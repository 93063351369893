import RouterUtils from 'utils/router'
import TrackingUtils from 'utils/tracking'

export default class AuthUtils {
  static async handleRedirectOnLogin(role: 'user' | 'standard', query: any) {
    if (role === 'standard') {
      TrackingUtils.event('company-login')
      await RouterUtils.push(query.next || '/profile/home')
    } else if (role === 'user') {
      TrackingUtils.event('freelancer-login')
      await RouterUtils.push(query.next || '/freelancers/profile/home')
    }
  }
}
