import React from 'react'
import useTranslation from 'translations/hooks/useTranslation'
import ButtonSecondary from 'components/ButtonSecondary'

const LoginBanner: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="container container--33 job-packages-login-banner">
      <div className="job-packages-login-banner-inner">
        <div className="margin-bottom-super-small flex-column flex">
          <span className="heading-2 no-margin uppercase relative company__banner-heading company__banner-heading--no-data">
            {t('jobs.loginBanner.title')}
          </span>
          <span className="heading-1 relative job-packages-login-banner-sub-heading no-margin uppercase relative company__banner-heading company__banner-heading--no-data">
            {t('jobs.loginBanner.free')}
          </span>
        </div>
        <span className="paragraph paragraph--small paragraph--bold">
          {t('jobs.loginBanner.desc')}
        </span>
        <br />
        <span className="paragraph paragraph--small paragraph--bold">
          <span className="job-packages-login-banner-pill">4+1</span>{' '}
          <span>{t('jobs.loginBanner.or')}</span>{' '}
          <span className="job-packages-login-banner-pill">7+3</span>{' '}
          <span>{t('jobs.loginBanner.freeDesc')}.</span>
        </span>
        <br />
        <ButtonSecondary
          text={t('jobs.loginBanner.cta')}
          className="center margin-top"
          href="/cennik"
          color="black-white"
          size="big"
        />
      </div>
    </div>
  )
}

export default LoginBanner
