import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Page from 'pages/login'
import { redirectIfCompanyLogged } from 'utils/helpers'

const pageConf = {
  context: 'login',
  title: 'login',
  description: 'login',
}

interface IProps {
  query: any
  user: any
}

class Login extends PureComponent<IProps> {
  static getInitialProps({ query }) {
    return {
      pageConf,
      query,
    }
  }

  componentDidMount() {
    redirectIfCompanyLogged(this.props.user)
  }

  render() {
    return <Page {...this.props} />
  }
}

const mapStateToProps = ({ user }) => ({ user })
export default connect(mapStateToProps)(Login)
