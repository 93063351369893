import React, { FC, useEffect, useState } from 'react'
import classnames from 'classnames'
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel'
import useTranslation from 'translations/hooks/useTranslation'

interface IProps {
  fold?: boolean
  white?: boolean
}

const shuffle = (array: any[]) => {
  const shuffled = [...array]

  shuffled.sort(() => Math.random() - 0.5)

  return shuffled
}

const TESTIMONIALS = [
  {
    i18nKey: 'testimonials.luigisBox',
    author: `Gejza Nagy, CEO & Co-founder`,
    company: `Luigi's Box`,
    url: `https://pretlak.com/company/luigis-box-sro`,
    img: `/images/testimonials/big-luigisbox.png`,
  },
  {
    i18nKey: 'testimonials.slido',
    author: `František Krivda, Co-founder`,
    company: `Slido`,
    url: `https://pretlak.com/company/sli.do-s.-r.-o.`,
    img: `/images/testimonials/big-slido.png`,
  },
  {
    i18nKey: 'testimonials.berlinBrandsGroup',
    author: `Jakub Zilincan, Chief Marketing Officer`,
    company: `Berlin Brands Group`,
    url: `https://pretlak.com/company/berlin-brands-group-a.s.`,
    img: `/images/testimonials/big-bbg.png`,
  },
  {
    i18nKey: 'testimonials.sygic',
    author: 'Andrea Keprtová, Head of HR',
    company: 'Sygic',
    url: 'https://pretlak.com/company/sygic-as',
    img: `/images/testimonials/big-sygic.png`,
  },
  {
    i18nKey: 'testimonials.barneyStudio',
    author: 'Denis Fino, CEO',
    company: 'Barney Studio',
    url: 'https://pretlak.com/company/barney-studio',
    img: `/images/testimonials/big-barney.png`,
  },
  {
    i18nKey: 'testimonials.twoCreateAdvertising',
    author: 'Martin Porada, CEO',
    company: '2CREATE advertising',
    url: 'https://pretlak.com/company/2create-advertising-sro',
    img: `/images/testimonials/big-2create.png`,
  },
  {
    i18nKey: 'testimonials.imagons',
    author: 'Miroslav Nagy, CEO',
    company: 'Imagons',
    url: 'https://pretlak.com/company/imagons-sro',
    img: `/images/testimonials/big-imagons.png`,
  },
  {
    i18nKey: 'testimonials.darenAndCurtis',
    author: 'Katarína Hudecová, Operations Manager',
    company: 'daren&curtis',
    url: 'https://pretlak.com/company/daren-and-curtis-sro',
    img: `/images/testimonials/big-daren.png`,
  },
  // {
  //   i18nKey: 'testimonials.gecora',
  //   author: 'Adam Kovařík, CEO',
  //   company: 'gecora.com',
  //   url: 'https://pretlak.com/company/gecora-s-r-o',
  //   img: `/images/testimonials/big-gecora.png`,
  // },
  {
    i18nKey: 'testimonials.kesu',
    author: 'Matúš Granec, CEO',
    company: 'Kešu Advertising',
    url: 'https://pretlak.com/company/kesu-advertising-sro',
    img: `/images/testimonials/big-kesu.png`,
  },
  // {
  //   i18nKey: 'testimonials.gymbeam',
  //   author: 'Ján Buček, Lead Recruiter',
  //   company: 'GymBeam',
  //   url: 'https://pretlak.com/company/gymbeam-s.r.o.',
  //   img: `/images/testimonials/big-gymbeam.png`,
  // },
  {
    i18nKey: 'testimonials.tvjoj',
    author: 'Zuzana Adamcová, HR Manager',
    company: 'TV JOJ',
    url: 'https://pretlak.com/company/slovenska-produkcna-as',
    img: `/images/testimonials/big-tvjoj.png`,
  },
  {
    i18nKey: 'testimonials.ovb',
    author: 'Peter Gavalčin, Head of HR',
    company: 'OVB Allfinanz Slovensko',
    url: 'https://pretlak.com/company/ovb-allfinanz-slovensko-a-s',
    img: `/images/testimonials/big-ovb.png`,
  },
]

const TestimonialsBig: FC<IProps> = ({ fold, white }) => {
  const [testimonials, setTestimonials] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    setTestimonials(shuffle(TESTIMONIALS))
  }, [])

  return (
    <div className="carousel-big">
      <CarouselProvider
        naturalSlideWidth={600}
        naturalSlideHeight={460}
        totalSlides={testimonials.length}
        interval={6000}
        isPlaying
        infinite
      >
        <div
          className={classnames({
            'carousel-black': !white,
            'carousel-white': white,
            'carousel-big__fold': fold,
          })}
        >
          <div className="container relative">
            <Slider>
              {testimonials.map((item, index) => {
                return (
                  <Slide index={index} key={index}>
                    <div className="carousel-big__slide-content">
                      <div className="carousel-big__text-wrapper">
                        <p className="carousel-big__text">{`"${t(item.i18nKey)}"`}</p>
                        <p className="carousel-big__author-name">
                          – {item.author} <a href={item.url}>{item.company}</a>
                        </p>
                      </div>
                      <div className="carousel-big__author">
                        <img alt={item.author} src={item.img} width="300" height="250" />
                      </div>
                    </div>
                  </Slide>
                )
              })}
            </Slider>

            <ButtonBack>
              <span className="button__in">
                <img
                  className="back__icon"
                  src="/images/icons/icon-back-black.svg"
                  alt="Prev"
                />
              </span>
            </ButtonBack>
            <ButtonNext>
              <span className="button__in">
                <img
                  className="back__icon back__icon--next"
                  src="/images/icons/icon-back-black.svg"
                  alt="Next"
                />
              </span>
            </ButtonNext>
          </div>
        </div>
      </CarouselProvider>
    </div>
  )
}

export default React.memo(TestimonialsBig, (prevP, nextP) => prevP === nextP)
